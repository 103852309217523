import SetupCategories from "@/components/SetupCategories"
import useRpc from "@/src/useRpc";
import LoadingSpinner from "./LoadingSpinner";
import { useState } from "react";
import Modal from "./Modal";
import useCurrentOrg, { refreshCurrentOrg } from "@/src/useCurrentOrg";
import { pluralCount } from "@/lib/string";
import SetupFrequency from "./SetupFrequency";
import { sendMessage } from "@/src/useMessage";
import ProgressBar from "./ProgressBar";


export default function TopicSettings({}) {
  const org = useCurrentOrg()
  const [catalogs, error, reloadTopics] = useRpc('signup.catalogsAndCategories')
  const [editingTopics, setEditingTopics] = useState(false)
  const [showFrequency, setShowFrequency] = useState(false)
  const [rebuilding, setRebuilding] = useState(false)
  const [rebuildProgress, setRebuildProgress] = useState(0)

  const {postsPerWeekMin, postsPerWeekMax} = (org || {})
  let postsPerWeekText = `${postsPerWeekMin}-${pluralCount(postsPerWeekMax, 'time')} per week`
  if (postsPerWeekMin === postsPerWeekMax) postsPerWeekText = `${pluralCount(postsPerWeekMin, 'time')} per week`

  async function handleRebuildTask (task) {
    setEditingTopics(false)
    setShowFrequency(false)

    if (!task) return

    setRebuildProgress(0)
    setRebuilding(true)
    try {
      await task.done(percent => {
        // The task progress looks like it stalls at 80%, so we'll fake it a bit
        if (percent < 80) {
          setRebuildProgress(percent / 2)
        } else {
          setRebuildProgress(v => Math.min(v + 5, 95))
        }
      })
      setRebuildProgress(100)
    } catch (err) {
      console.error(err)
    } finally {
      refreshCurrentOrg()
      sendMessage('refreshCalendar')
      await reloadTopics()
      setRebuilding(false)
    }
  }

  if (!catalogs) return <>
    <div className="py-6">
      <LoadingSpinner size="medium" />
    </div>
  </>

  return <>
    <div className="flex flex-col bg-f3f3f3 absolute-fill relative overflow-hidden">
      <div className="px-5 pb-3 shadow bg-white z-up">
        { org &&
          <p className="px-1 py-1">
            Your goal is to post <a className="link nowrap" onClick={() => setShowFrequency(true)}>
            {postsPerWeekText}</a> on the following topics:
          </p>
        }

      </div>

      <div className="flex-grow overflow-auto-y">
        <SetupCategories catalogs={catalogs} compact readonly useLoading={isLoaded => !isLoaded}/>
      </div>

      <div className="text-center bg-white left right py-2 shadow-top z-1">
        <button className="button is-primary" onClick={() => setEditingTopics(v => !v)}>
          Change Topics
        </button>
      </div>
    </div>

    <Modal visible={editingTopics}>
      <div className="flex-grow overflow-auto">
        <SetupCategories catalogs={catalogs} onClose={handleRebuildTask} useLoading={isLoaded => !isLoaded}/>
      </div>
    </Modal>

    <Modal visible={showFrequency} onClickOutside={handleRebuildTask}>
      <div className="flex-grow overflow-auto">
        <SetupFrequency onClose={handleRebuildTask}/>
      </div>
    </Modal>

    <Modal visible={rebuilding}>
      <div className="p-4 text-center flex-col">
        <LoadingSpinner size="normal"/>
        <p className="gray mb-1">
          Rebuilding calendar...
        </p>
        <ProgressBar value={rebuildProgress}/>
      </div>
    </Modal>

  </>
}
