import Drawer from './Drawer'
import { memo } from 'react'
import TopNav from '@/components/TopNav'
import SettingsScreen from './SettingsScreen'
import { useAppRouter } from '@/src/useAppRouter'
import classNames from 'classnames'
import AccountLocked from './AccountLocked'
import { MonthView } from './MonthView'
import PostPanel from './PostPanel'
import Modal from './Modal'
import PostGroupEditor from './PostGroupEditor'
import PostGroupLocked from './PostGroupLocked'
import LeftNav from './LeftNav'
import useCurrentOrg from '@/src/useCurrentOrg'


export default memo(function HomeScreenDesktop ({date}) {
  const router = useAppRouter()

  const {popover} = router.query
  const org = useCurrentOrg()
  const cancelled = org?.status === 'canceled'

  return <>
    <div className="layout">

      <div className="top-nav">
        <TopNav date={date} showMonthNav={true}/>
      </div>

      <div className={classNames("main gray-background", {locked: cancelled})}>
        <div className="content-area">
          <div className='flex-no-shrink shadow' style={{zIndex: 22}}>
            <LeftNav date={date}/>
          </div>
          <div className="full-width full-height flex flex-col">
            <div className="calendar-container relative">
              { cancelled && <AccountLocked/> }

              <div className="left-nav-drawer-container">
                <Drawer title="Settings" position="left" active={popover === 'settings'} modal onClickOutside={() => router.goto('home')}>
                  <div className="drawer-content">
                    <SettingsScreen date={date} />
                  </div>
                </Drawer>
              </div>
              <div className="calendar-wrapper absolute-fill">
                <MonthView date={date}/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal visible={popover === 'create'}>
        <PostPanel date={date}/>
      </Modal>

      <Modal visible={popover === 'edit'}>
        <PostGroupEditor date={date}/>
      </Modal>

      <Modal visible={popover === 'locked'}>
        <PostGroupLocked date={date}/>
      </Modal>

    </div>

    <style jsx>{`
      .locked .calendar-wrapper {
        z-index: 0;
        pointer-events: none;
        opacity: 0.5;
      }
      .layout {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
      .top-nav {
        flex-grow: 0;
        z-index: 50;
      }
      .main {
        display: flex;
        height: 100%;
        flex-grow: 1;
        overflow: hidden;
        overscroll-behavior: contain;
      }
      .content-area {
        display: flex;
        flex: 1;
        overflow-y: hidden;
      }
      .calendar-scroll-container {
        flex-grow: 0;
        flex-shrink: 0;
        width: 340px;
        background-color: white;
        border-right: 1px solid #ddd;
        overflow-x: hidden;
      }
      .calendar-container {
        flex-grow: 1;
        flex-shrink: 0;
        background-color: white;
        border-right: 1px solid #ddd;
        overflow: hidden;
        max-width: 100%;
      }
      .right-panel {
        flex-grow: 0;
        flex-shrink: 0;
        background-color: white;
        overflow-x: hidden;
        position: relative;
        box-shadow: inset 3px 0 3px rgba(0 0 0 / 0.1);
      }
      .post-area {
        box-shadow: inset 0 0 4px rgba(0,0,0, 0.1);
        flex-grow: 1;
        min-height: 100vh;
      }
      .calendar, .post-area, .right-panel {
        height: 100%;
        overflow-y: auto;
      }
      .post-area {
        z-index: 10;
        overflow-y: scroll;
      }
      .drawer-container {
        pointer-events: none;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        width: 350px;
        z-index: 51;
      }
      .small-drawer-container,
      .big-drawer-container {
        top: 0;
        bottom: 0;
        z-index: 200;
      }
      .big-drawer-container {
        width: 880px;
        max-width: 90vw;
      }
      .left-nav-drawer-container {
        pointer-events: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 350px;
        z-index: 20;
      }
      .drawer-content {
        pointer-events: all;
        height: 100%
      }
      .no-right-panel {
        display: none;
      }
      @media only screen and (max-width: 1199px) {
        .right-panel {
          display: none;
        }
        .no-right-panel {
          display: unset;
        }
        .post-area {
          margin-right: 0;
        }
      }
      @media only screen and (max-width: 699px) {
        .calendar {
          display: none;
        }
        .post-area {
          margin-left: 0;
        }
      }
    `}</style>
  </>
})
